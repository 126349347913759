import {
  Box_default,
  Button,
  DropdownMenu,
  DropdownMenuPrimitives,
  Flex,
  Icon_default,
  Separator,
  TextField,
  __objRest,
  __spreadProps,
  __spreadValues,
  styled
} from "./chunk-6HHVSV2Z.mjs";

// src/LexicalEditor/index.tsx
import {
  BeautifulMentionNode as LexicalMentionNode,
  PlaceholderNode,
  PlaceholderPlugin
} from "lexical-beautiful-mentions";

// src/LexicalEditor/LexicalEditor.tsx
import { $generateHtmlFromNodes } from "@lexical/html";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { $getRoot } from "lexical";

// src/LexicalEditor/plugins/LexicalOnChange.tsx
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
function OnChangePlugin({
  onChange
}) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState, editor);
    });
  }, [editor, onChange]);
  return null;
}

// src/LexicalEditor/plugins/LexicalToolbar.tsx
import { t } from "@kandji-inc/nectar-i18n";
import { $createCodeNode, $isCodeNode } from "@lexical/code";
import { $createLinkNode, $isLinkNode } from "@lexical/link";
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode
} from "@lexical/list";
import { useLexicalComposerContext as useLexicalComposerContext2 } from "@lexical/react/LexicalComposerContext";
import { $createQuoteNode, $isHeadingNode } from "@lexical/rich-text";
import { $setBlocksType } from "@lexical/selection";
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister
} from "@lexical/utils";
import {
  $createParagraphNode,
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND
} from "lexical";
import { useEffect as useEffect2, useState as useState2 } from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var ToolbarButton = styled(Button, {
  padding: "3px !important"
});
var blockTypeToBlockName = {
  code: "Code",
  paragraph: "Normal",
  quote: "Block quote"
};
function LexicalToolbar() {
  const [editor] = useLexicalComposerContext2();
  const [isBold, setIsBold] = useState2(false);
  const [isItalic, setIsItalic] = useState2(false);
  const [isBulletList, setIsBulletList] = useState2(false);
  const [isNumberedList, setIsNumberedList] = useState2(false);
  const [keyModifer, setKeyModifier] = useState2(blockTypeToBlockName.paragraph);
  const [linkDetails, setLinkDetails] = useState2({
    isOpen: false,
    title: "",
    target: "",
    nodeKey: ""
  });
  const applyLink = (title, target, nodeKey) => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const linkNode = $createLinkNode(target, {
          title,
          target,
          rel: "noopener noreferrer"
        });
        const textNode = $createTextNode(title);
        linkNode.append(textNode);
        if (nodeKey) {
          const previousTextNode = selection.getNodes().find((node) => {
            var _a;
            return ((_a = node.getParent()) == null ? void 0 : _a.getKey()) === nodeKey;
          });
          if (previousTextNode) {
            const parentLinkNode = previousTextNode.getParent();
            parentLinkNode == null ? void 0 : parentLinkNode.insertBefore(linkNode, !!previousTextNode);
            parentLinkNode == null ? void 0 : parentLinkNode.remove();
          }
        } else {
          selection.insertNodes([linkNode]);
        }
      }
    });
  };
  const $updateToolbar = () => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
    }
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element = anchorNode.getKey() === "root" ? anchorNode : $findMatchingParent(anchorNode, (e) => {
        const parent = e.getParent();
        return parent !== null && $isRootOrShadowRoot(parent);
      });
      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      const anchorParentNode = anchorNode.getParent();
      if (anchorParentNode && $isLinkNode(anchorParentNode)) {
        const url = anchorParentNode.getURL();
        const text = anchorParentNode.getTextContent();
        setLinkDetails((prev) => __spreadProps(__spreadValues({}, prev), {
          title: text,
          target: url,
          nodeKey: anchorParentNode.getKey()
        }));
      } else {
        setLinkDetails({
          isOpen: false,
          title: "",
          target: "",
          nodeKey: ""
        });
      }
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(
            anchorNode,
            ListNode
          );
          const type = parentList ? parentList.getListType() : element.getListType();
          setKeyModifier(
            type in blockTypeToBlockName ? blockTypeToBlockName[type] : blockTypeToBlockName.paragraph
          );
          setIsBulletList(type === "bullet");
          setIsNumberedList(type === "number");
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setKeyModifier(
            type in blockTypeToBlockName ? blockTypeToBlockName[type] : blockTypeToBlockName.paragraph
          );
          setIsBulletList(type === "bullet");
          setIsNumberedList(type === "number");
          if ($isCodeNode(element)) {
            setKeyModifier(blockTypeToBlockName.code);
            return;
          }
        }
      }
    }
  };
  useEffect2(() => {
    return mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          $updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      })
    );
  }, [editor, $updateToolbar]);
  return /* @__PURE__ */ jsx(Box_default, { z5: true, wFull: true, css: { padding: "6px" }, children: /* @__PURE__ */ jsxs(Flex, { gap: "xs", alignItems: "center", children: [
    /* @__PURE__ */ jsx(
      ToolbarButton,
      {
        variant: isBold ? "default" : "subtle",
        icon: { name: "bold" },
        compact: true,
        onClick: () => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }
      }
    ),
    /* @__PURE__ */ jsx(
      ToolbarButton,
      {
        variant: isItalic ? "default" : "subtle",
        icon: { name: "italic" },
        compact: true,
        onClick: () => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }
      }
    ),
    /* @__PURE__ */ jsx(Separator, { orientation: "vertical", css: { height: "16px !important" } }),
    /* @__PURE__ */ jsx(
      DropdownMenu,
      {
        options: [
          {
            label: t("Normal"),
            id: "normal",
            onClick: () => {
              editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                  $setBlocksType(selection, () => $createParagraphNode());
                }
              });
            }
          },
          {
            label: t("Block quote"),
            id: "block",
            onClick: () => {
              if (keyModifer !== blockTypeToBlockName.quote) {
                editor.update(() => {
                  const selection = $getSelection();
                  $setBlocksType(selection, () => $createQuoteNode());
                });
              }
            }
          },
          {
            label: t("Code"),
            id: "code",
            onClick: () => {
              if (keyModifer !== blockTypeToBlockName.code) {
                editor.update(() => {
                  let selection = $getSelection();
                  if (selection !== null) {
                    if (selection.isCollapsed()) {
                      $setBlocksType(selection, () => $createCodeNode());
                    } else {
                      const textContent = selection.getTextContent();
                      const codeNode = $createCodeNode();
                      selection.insertNodes([codeNode]);
                      selection = $getSelection();
                      if ($isRangeSelection(selection)) {
                        selection.insertRawText(textContent);
                      }
                    }
                  }
                });
              }
            }
          }
        ],
        withArrow: false,
        contentProps: { align: "start" },
        css: { zIndex: "1000" },
        children: /* @__PURE__ */ jsxs(
          Button,
          {
            variant: "subtle",
            compact: true,
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
            },
            children: [
              keyModifer,
              /* @__PURE__ */ jsx(Flex, { ml1: true, children: /* @__PURE__ */ jsx(Icon_default, { name: "fa-angle-down-small", size: "sm" }) })
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(Separator, { orientation: "vertical", css: { height: "16px !important" } }),
    /* @__PURE__ */ jsx(
      ToolbarButton,
      {
        variant: isBulletList ? "default" : "subtle",
        icon: { name: "list" },
        compact: true,
        onClick: () => {
          if (!isBulletList) {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, void 0);
          } else {
            editor.update(() => {
              const selection = $getSelection();
              if ($isRangeSelection(selection)) {
                $setBlocksType(selection, () => $createParagraphNode());
              }
            });
          }
        }
      }
    ),
    /* @__PURE__ */ jsx(
      ToolbarButton,
      {
        variant: isNumberedList ? "default" : "subtle",
        icon: { name: "list-ol" },
        compact: true,
        onClick: () => {
          if (!isNumberedList) {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, void 0);
          } else {
            editor.update(() => {
              const selection = $getSelection();
              if ($isRangeSelection(selection)) {
                $setBlocksType(selection, () => $createParagraphNode());
              }
            });
          }
        }
      }
    ),
    /* @__PURE__ */ jsx(Separator, { orientation: "vertical", css: { height: "16px !important" } }),
    /* @__PURE__ */ jsxs(
      DropdownMenuPrimitives.Root,
      {
        modal: false,
        open: linkDetails.isOpen,
        onOpenChange: (isOpen) => setLinkDetails(__spreadProps(__spreadValues({}, linkDetails), { isOpen })),
        children: [
          /* @__PURE__ */ jsx(
            DropdownMenuPrimitives.Trigger,
            {
              asChild: true,
              onClick: (e) => e.stopPropagation(),
              children: /* @__PURE__ */ jsx(
                ToolbarButton,
                {
                  variant: "subtle",
                  icon: { name: "link-horizontal" },
                  compact: true
                }
              )
            }
          ),
          /* @__PURE__ */ jsx(
            DropdownMenuPrimitives.Content,
            {
              css: {
                zIndex: 1e3,
                width: "320px",
                marginTop: "4px"
              },
              align: "start",
              side: "bottom",
              children: /* @__PURE__ */ jsxs(Flex, { flow: "column", gap: "xs", children: [
                /* @__PURE__ */ jsx(Box_default, { css: { padding: "6px 12px" }, children: /* @__PURE__ */ jsx(
                  TextField,
                  {
                    label: t("Link title"),
                    placeholder: t("Enter link title"),
                    value: linkDetails.title,
                    onChange: (e) => setLinkDetails(__spreadProps(__spreadValues({}, linkDetails), { title: e.target.value }))
                  }
                ) }),
                /* @__PURE__ */ jsx(Box_default, { css: { padding: "6px 12px" }, children: /* @__PURE__ */ jsx(
                  TextField,
                  {
                    label: t("Link target"),
                    placeholder: t("Enter link target"),
                    value: linkDetails.target,
                    onChange: (e) => setLinkDetails(__spreadProps(__spreadValues({}, linkDetails), { target: e.target.value })),
                    tabIndex: 0
                  }
                ) }),
                /* @__PURE__ */ jsxs(
                  Flex,
                  {
                    alignItems: "center",
                    justifyContent: "end",
                    gap: "md",
                    css: { padding: "6px 12px" },
                    children: [
                      /* @__PURE__ */ jsx(
                        Button,
                        {
                          compact: true,
                          onClick: () => setLinkDetails({
                            isOpen: false,
                            title: "",
                            target: "",
                            nodeKey: ""
                          }),
                          children: t("Cancel")
                        }
                      ),
                      /* @__PURE__ */ jsx(
                        Button,
                        {
                          compact: true,
                          disabled: !linkDetails.title || !linkDetails.target,
                          onClick: () => applyLink(
                            linkDetails.title,
                            linkDetails.target,
                            linkDetails.nodeKey
                          ),
                          children: t("Apply")
                        }
                      )
                    ]
                  }
                )
              ] })
            }
          )
        ]
      }
    )
  ] }) });
}

// src/LexicalEditor/LexicalEditor.tsx
import { jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
var LexicalEditorContainer = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  borderRadius: "$rounded",
  border: "1px solid $text_input_default_border",
  "&:focus-within": {
    borderColor: "$text_input_default_border_focus"
  }
});
var StyledContentEditable = styled(ContentEditable, {
  outline: "unset",
  position: "relative",
  padding: "8px",
  "& code": {
    all: "unset",
    fontFamily: "$code",
    fontSize: "$2",
    lineHeight: "$2",
    paddingLeft: "4px",
    background: "rgba(80, 94, 113, 0.06)",
    border: "1px solid rgba(80, 94, 113, 0.12)",
    borderRadius: "$rounded",
    color: "$neutral80",
    display: "block",
    wordWrap: "break-word"
  },
  "& .text-bold.text-italic": {
    fontWeight: "$semibold",
    fontStyle: "italic"
  },
  "& .text-link": {
    textDecoration: "underline",
    color: "$blue50"
  },
  "&:read-only": {
    backgroundColor: "$input_background_enabled",
    cursor: "not-allowed"
  }
});
var StyledPlaceholder = styled("div", {
  color: "$label_secondary_text_disabled",
  overflow: "hidden",
  position: "absolute",
  textOverflow: "ellipsis",
  top: "$2",
  left: "$2",
  fontWeight: "$regular",
  userSelect: "none",
  display: "inline-block",
  pointerEvents: "none",
  fontSize: "$2",
  variants: {
    hasToolbar: {
      true: {
        top: "48px",
        left: "8px"
      }
    }
  }
});
var beautifulMentionsTheme = {
  // Use the trigger name as the key
  $: "mentions-chip"
};
function LexicalEditor({
  children: plugins,
  placeholder,
  initialConfig = {},
  editorCss = {},
  themeCss = {},
  onEditorChange,
  withToolbar = false
}) {
  const defaultConfig = __spreadValues({
    namespace: "LexicalEditor",
    theme: __spreadValues({
      beautifulMentions: beautifulMentionsTheme,
      link: "text-link",
      text: {
        bold: "text-bold",
        italic: "text-italic",
        boldItalic: "text-bold text-italic"
      }
    }, themeCss),
    onError: (error) => {
      console.error("Lexical Error:", error);
    }
  }, initialConfig);
  function onChange(editorState, editor) {
    const stringifiedEditorState = JSON.stringify(editorState.toJSON());
    const parsedEditorState = editor.parseEditorState(stringifiedEditorState);
    const html = editor.read(() => $generateHtmlFromNodes(editor));
    const editorStateTextString = parsedEditorState.read(() => {
      return $getRoot().getTextContent();
    });
    onEditorChange(editorStateTextString, html);
  }
  return /* @__PURE__ */ jsxs2(LexicalComposer, { initialConfig: defaultConfig, children: [
    /* @__PURE__ */ jsxs2(LexicalEditorContainer, { children: [
      withToolbar && /* @__PURE__ */ jsx2(LexicalToolbar, {}),
      /* @__PURE__ */ jsx2(
        RichTextPlugin,
        {
          contentEditable: /* @__PURE__ */ jsx2(
            StyledContentEditable,
            {
              css: __spreadValues({}, editorCss),
              "aria-placeholder": placeholder,
              placeholder: /* @__PURE__ */ jsx2(StyledPlaceholder, { hasToolbar: withToolbar, children: placeholder })
            }
          ),
          placeholder: /* @__PURE__ */ jsx2(StyledPlaceholder, { hasToolbar: withToolbar, children: placeholder }),
          ErrorBoundary: LexicalErrorBoundary
        }
      )
    ] }),
    plugins,
    /* @__PURE__ */ jsx2(HistoryPlugin, {}),
    /* @__PURE__ */ jsx2(AutoFocusPlugin, {}),
    /* @__PURE__ */ jsx2(OnChangePlugin, { onChange })
  ] });
}

// src/LexicalEditor/plugins/LexicalDisabled.tsx
import { useLexicalComposerContext as useLexicalComposerContext3 } from "@lexical/react/LexicalComposerContext";
import { useEffect as useEffect3 } from "react";
function LexicalDisabledPlugin({
  isDisabled
}) {
  const [editor] = useLexicalComposerContext3();
  useEffect3(() => {
    editor.setEditable(!isDisabled);
  }, [editor, isDisabled]);
  return null;
}

// src/LexicalEditor/plugins/LexicalMentions.tsx
import { Popover } from "@radix-ui/react-popover";
import {
  BeautifulMentionsPlugin
} from "lexical-beautiful-mentions";
import { forwardRef } from "react";
import { jsx as jsx3 } from "react/jsx-runtime";
var LexicalMenuItem = styled("li", {
  all: "unset",
  padding: "$2 $4",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  gap: "$4",
  cursor: "pointer",
  backgroundColor: "#fff",
  "&[aria-selected=true]": {
    backgroundColor: "rgba(80, 94, 113, 0.16)"
  },
  fontSize: "$1"
});
var LexicalMenuContent = styled("ul", {
  overflow: "auto",
  width: "min-content",
  padding: "$1 0px",
  background: "$neutral0",
  filter: "drop-shadow($elevation2)",
  borderRadius: "$rounded"
});
function CustomMenu(_a) {
  var _b = _a, {
    loading,
    menuCss
  } = _b, props = __objRest(_b, [
    "loading",
    "menuCss"
  ]);
  return /* @__PURE__ */ jsx3(Popover, { children: /* @__PURE__ */ jsx3(LexicalMenuContent, __spreadProps(__spreadValues({}, props), { css: menuCss })) });
}
var CustomMenuItem = forwardRef((_a, ref) => {
  var _b = _a, { selected, item } = _b, props = __objRest(_b, ["selected", "item"]);
  return /* @__PURE__ */ jsx3(LexicalMenuItem, __spreadProps(__spreadValues({}, props), { ref }));
});
function LexicalMentionsPlugin({
  mentionItems,
  menuCss,
  mentionItemLimit = 30
}) {
  return /* @__PURE__ */ jsx3(
    BeautifulMentionsPlugin,
    {
      items: mentionItems,
      menuComponent: (props) => /* @__PURE__ */ jsx3(CustomMenu, __spreadProps(__spreadValues({}, props), { menuCss })),
      menuItemComponent: CustomMenuItem,
      menuItemLimit: mentionItemLimit
    }
  );
}

// src/LexicalEditor/plugins/LexicalInitialState/utils/escapeRegex.tsx
function escapeRegExp(s) {
  return s.replace(/[\\^$.*+?()[\]{}|]/g, "\\$&");
}

// src/LexicalEditor/plugins/LexicalMentionsTriggers.ts
var LexicalMentionsTriggers = {
  DOLLAR: "\\$",
  AMPERSAND: "&",
  EXCLAMATION: "!",
  AT: "@",
  HASH: "#",
  ASTERISK: "*"
};
function buildMentionParser(items) {
  return (nodes) => {
    let parsedNodes = [];
    items.sort((a, b) => b.length - a.length);
    const mentionRegexString = `(${items.map((item) => `\\$${escapeRegExp(item)}`).join(`|`)})`;
    const mentionRegex = new RegExp(mentionRegexString);
    for (const node of nodes) {
      const { type, value } = node;
      if (type !== 0 /* Unknown */) {
        parsedNodes.push(node);
        continue;
      }
      const values = value.split(mentionRegex);
      const valueNodes = values.map((v) => {
        const type2 = mentionRegex.test(v) ? 1 /* Mention */ : 0 /* Unknown */;
        return {
          type: type2,
          value: v
        };
      });
      parsedNodes = parsedNodes.concat(valueNodes);
    }
    return parsedNodes;
  };
}

// src/LexicalEditor/plugins/LexicalSingleLine.tsx
import { useLexicalComposerContext as useLexicalComposerContext4 } from "@lexical/react/LexicalComposerContext";
import { mergeRegister as mergeRegister2 } from "@lexical/utils";
import { LineBreakNode, RootNode } from "lexical";
import { useEffect as useEffect4 } from "react";
function LexicalSingleLine() {
  const [editor] = useLexicalComposerContext4();
  useEffect4(
    () => mergeRegister2(
      editor.registerNodeTransform(RootNode, (rootNode) => {
        var _a;
        if (rootNode.getChildrenSize() <= 1)
          return;
        (_a = rootNode.getLastChild()) == null ? void 0 : _a.remove();
      }),
      editor.registerNodeTransform(LineBreakNode, (node) => {
        node.remove();
      })
    ),
    [editor]
  );
  return null;
}

// src/LexicalEditor/plugins/LexicalValidation.tsx
import { useLexicalComposerContext as useLexicalComposerContext5 } from "@lexical/react/LexicalComposerContext";
import { useEffect as useEffect5 } from "react";
import { Fragment, jsx as jsx4, jsxs as jsxs3 } from "react/jsx-runtime";
var LexicalValidationPlugin = ({
  isValid,
  helpMessage
}) => {
  const [editor] = useLexicalComposerContext5();
  useEffect5(() => {
    const editorElement = editor.getRootElement();
    if (editorElement) {
      if (!isValid) {
        editorElement.classList.add("invalid-editor");
      } else {
        editorElement.classList.remove("invalid-editor");
      }
    }
  }, [isValid, editor]);
  return !isValid ? /* @__PURE__ */ jsxs3("div", { className: "help-message", children: [
    /* @__PURE__ */ jsx4(Icon_default, { name: "triangle-exclamation" }),
    helpMessage
  ] }) : /* @__PURE__ */ jsx4(Fragment, {});
};

// src/LexicalEditor/index.tsx
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { $generateHtmlFromNodes as $generateHtmlFromNodes2 } from "@lexical/html";
import { AutoLinkNode, LinkNode as LinkNode2 } from "@lexical/link";
import { ListItemNode, ListNode as ListNode2 } from "@lexical/list";
import { useLexicalComposerContext as useLexicalComposerContext7 } from "@lexical/react/LexicalComposerContext";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { QuoteNode } from "@lexical/rich-text";
import { $createParagraphNode as $createParagraphNode3, $createTextNode as $createTextNode3, $getRoot as $getRoot3 } from "lexical";

// src/LexicalEditor/plugins/LexicalInitialState/LexicalInitialState.tsx
import { $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext as useLexicalComposerContext6 } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode as $createParagraphNode2, $getRoot as $getRoot2, $insertNodes } from "lexical";
import { useEffect as useEffect6 } from "react";

// src/LexicalEditor/plugins/LexicalInitialState/utils/getCreateNode.tsx
import { $createTextNode as $createTextNode2 } from "lexical";
import { $createBeautifulMentionNode } from "lexical-beautiful-mentions";
function getCreateNode(type) {
  switch (type) {
    case 1 /* Mention */:
      return (text) => $createBeautifulMentionNode("", text);
    case 0 /* Unknown */:
    default:
      return $createTextNode2;
  }
}

// src/LexicalEditor/plugins/LexicalInitialState/LexicalInitialState.tsx
function LexicalInitialStatePlugin({
  value = "",
  htmlValue,
  mentions
}) {
  const [editor] = useLexicalComposerContext6();
  const parsers = [];
  let parsedNodes = [{ type: 0 /* Unknown */, value }];
  if (mentions != null && mentions.parser != null) {
    parsers.unshift(mentions.parser);
  }
  const htmlToLexical = (html) => {
    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(html, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);
      $getRoot2().select();
      $insertNodes(nodes);
    });
  };
  useEffect6(() => {
    editor.update(() => {
      var _a;
      const root = $getRoot2();
      root.clear();
      if (htmlValue) {
        htmlToLexical(htmlValue);
      } else if (value) {
        const paragraphNode = $createParagraphNode2();
        parsers.forEach((parser) => parsedNodes = parser(parsedNodes));
        for (let i = 0; i < parsedNodes.length; i++) {
          const node = parsedNodes[i];
          const createNode = getCreateNode(node.type);
          if (createNode) {
            paragraphNode.append(createNode(node.value));
          }
        }
        (_a = paragraphNode.getLastChild()) == null ? void 0 : _a.selectEnd();
        root.append(paragraphNode);
      }
    });
  }, [editor, value.split]);
  return null;
}

// src/LexicalEditor/index.tsx
var ToolbarNodes = [
  ListNode2,
  ListItemNode,
  QuoteNode,
  CodeNode,
  PlaceholderNode,
  LinkNode2,
  AutoLinkNode
];
var ToolbarPlugins = [ListPlugin];
var LexicalEditor_default = {
  LexicalEditor,
  $getRoot: $getRoot3,
  $createTextNode: $createTextNode3,
  $createParagraphNode: $createParagraphNode3,
  LexicalMentionsTriggers,
  LexicalMentionsPlugin,
  buildMentionParser,
  LexicalDisabledPlugin,
  LexicalMentionNode,
  LexicalInitialStatePlugin,
  LexicalValidationPlugin,
  PlaceholderPlugin,
  PlaceholderNode,
  LexicalSingleLine,
  $generateHtmlFromNodes: $generateHtmlFromNodes2,
  useLexicalComposerContext: useLexicalComposerContext7,
  ListPlugin,
  ListNode: ListNode2,
  ListItemNode,
  QuoteNode,
  CodeHighlightNode,
  CodeNode,
  LinkNode: LinkNode2,
  AutoLinkNode,
  ToolbarNodes,
  ToolbarPlugins
};
export {
  LexicalEditor_default as default
};
